import { AuthProvider } from '@mit/aws-react'
import { getActiveRegion } from 'utility/Environment'

export const prepareHeaders = async (headers: Headers): Promise<Headers> => {
  const authorizationToken = await new AuthProvider(getActiveRegion()).getToken()
  headers.append('Authorization', `Bearer ${authorizationToken}`)
  return headers
}

export const getApiBaseUrl = (): string => {
  return getActiveRegion().api.domain
}
